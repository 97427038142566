<template>
  <v-container fluid>
    <v-form>
      <v-row>
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-database-cog"
            inline
            class="px-5 py-3"
            style="min-height: 780px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                SQL Server :: dados para conexão
              </div>
            </template>
            <v-row class="mt-5">
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Nome do Servidor"
                >
                  <v-text-field
                    label="Nome do Servidor"
                    v-model="servidor.nome"
                    outlined
                    maxlength="100"
                    autofocus
                    clearable
                    placeholder="Exemplo: GeoPerdas ANEEL"
                    hint="Um nome para identificar o servidor na aplicação"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col cols="12">
                <select-company
                  :type="'outlined'"
                  :selectedCompany="userLoggedCompany"
                  @companySelected="companySelected"
                />
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Database"
                >
                  <v-text-field
                    label="Database"
                    v-model="servidor.database"
                    outlined
                    maxlength="100"
                    clearable
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Host"
                >
                  <v-text-field
                    label="Host"
                    v-model="servidor.host"
                    outlined
                    maxlength="255"
                    clearable
                    hint="O endereço do servidor na rede, podendo ser um IP ou um domínio"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Port"
                >
                  <v-text-field
                    label="Port"
                    v-model="servidor.port"
                    outlined
                    type="number"
                    :min="portRange.min"
                    :max="portRange.max"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col cols="6">
                <v-text-field
                  label="Username"
                  v-model="servidor.username"
                  outlined
                  maxlength="100"
                  clearable
                  autocomplete="off"
                  hint="O usuário precisa ter permissões administrativas altas"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Password"
                  v-model="servidor.password"
                  outlined
                  maxlength="255"
                  autocomplete="off"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                  hint="A senha de acesso ao banco de dados"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="green darken-1"
                  min-width="100"
                  style="float: right"
                  class="mt-n5 mr-0"
                  @click="testarConexao()"
                  :disabled="!dadosConexaoPreenchidos"
                  :loading="loading"
                >
                  Testar Conexão
                </v-btn>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-connection"
            inline
            class="px-5 py-3"
            style="min-height: 780px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">
                Status de conexão com o servidor
              </div>
            </template>
            <v-row class="mt-5">
              <v-col cols="12">
                <v-alert
                  dense
                  outlined
                  type="success"
                  color="green"
                  class="py-1 px-1"
                  v-if="resultadoTesteConexaoServidor"
                >
                  Conexão com o servidor realizada com sucesso.
                </v-alert>
                <v-alert
                  dense
                  outlined
                  color="deep-orange"
                  icon="mdi-alert"
                  class="py-1 px-1"
                  v-else
                >
                  Não foi possível se conectar ao servidor ou o teste ainda não
                  foi realizado.
                  <div v-if="mensagemErroTesteConexaoServidor">
                    Erro: {{ mensagemErroTesteConexaoServidor }}
                  </div>
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-if="resultadoTesteConexaoServidor">
              <v-col cols="12">
                <div class="display-1 font-weight-light">
                  Databases encontradas no servidor:
                </div>
                <v-divider class="mt-2 mb-5" />
                <v-chip
                  class="ma-2"
                  color="grey lighten-3"
                  variant="outlined"
                  v-for="database in databases"
                  :key="database"
                >
                  {{ database }}
                </v-chip>
                <div v-if="bancosDadosDisponiveisCriacao.length">
                  <div class="display-1 font-weight-light mt-5">
                    Databases disponíveis para criação:
                  </div>
                  <v-divider class="mt-2 mb-5" />
                  <v-chip-group
                    v-model="criarDatabases"
                    column
                    multiple
                  >
                    <v-chip
                      filter
                      variant="outlined"
                      v-for="database in bancosDadosDisponiveisCriacao"
                      :key="database"
                      :value="database"
                    >
                      {{ database }}
                    </v-chip>
                  </v-chip-group>
                </div>
                <div>
                  <div class="display-1 font-weight-light mt-5">
                    Criar databases com sufixo customizado:
                  </div>
                  <v-divider class="mt-2 mb-5" />
                  <v-text-field
                    label="Sufixo customizado"
                    v-model="sufixoCustomizado"
                    outlined
                    maxlength="100"
                    autofocus
                    clearable
                    :placeholder="exemploSufixoCustomizado"
                  />
                  <v-alert
                    dense
                    outlined
                    type="info"
                    color="blue"
                    class="py-1 px-1"
                    v-if="databaseSufixoCustomizado"
                  >
                    Serão criadas databases com os nomes GEO_SIGR_{{
                      databaseSufixoCustomizado
                    }}
                    e GeoPerdas{{ databaseSufixoCustomizado }}.
                  </v-alert>
                </div>
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            min-width="100"
            style="float: right"
            class="mt-n5 mr-0"
            @click="save()"
            :disabled="!resultadoTesteConexaoServidor"
            :loading="loading"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ServidoresBancosDadosGeoPerdasService from '@/services/ServidoresBancosDadosGeoPerdasService';
import { difference } from 'underscore';
import routes from '@/store/modules/routes';

export default {
  components: {
    SelectCompany: () => import('@/components/general/SelectCompany.vue')
  },
  data: () => ({
    servidor: {
      id: null,
      nome: '',
      database: '',
      host: '',
      port: 1433,
      username: '',
      password: ''
    },
    portRange: {
      min: 1,
      max: 65536
    },
    showPassword: false,
    loading: false,
    resultadoTesteConexaoServidor: false,
    mensagemErroTesteConexaoServidor: null,
    databases: [],
    criarDatabases: [],
    sufixoCustomizado: '',
    companyId: null
  }),
  mounted() {
    if (this.servidorId) {
      this.getServer(this.servidorId);
    }
  },
  computed: {
    userLoggedCompany() {
      return this.$store.getters.getSelectedCompany;
    },
    servidorId() {
      return this.$route.params.id;
    },
    dadosConexaoPreenchidos() {
      const { nome, database, host, port, username, password } = this.servidor;
      return !!(
        nome &&
        this.companyId &&
        database &&
        host &&
        port &&
        Number(port) >= this.portRange.min &&
        Number(port) <= this.portRange.max &&
        username &&
        password
      );
    },
    bancosDadosProgGeoPerdas() {
      const bancosDados = ['GEO_SIGR_DDAD_M10'];
      return bancosDados;
    },
    bancosDadosDisponiveisCriacao() {
      return difference(this.bancosDadosProgGeoPerdas, this.databases);
    },
    databaseSufixoCustomizado() {
      return this.sufixoCustomizado.toUpperCase().replace(' ', '_');
    },
    exemploSufixoCustomizado() {
      const siglaEmpresa = this.$store.getters.getCompanyAcronym;
      const anoAtual = new Date().getFullYear();
      const anoAnterior = anoAtual - 1;
      return `Exemplo: ${siglaEmpresa}_${anoAnterior}`;
    }
  },
  methods: {
    getServer(id) {
      ServidoresBancosDadosGeoPerdasService.getById(id)
        .then((response) => {
          this.servidor = response;
          this.testarConexao();
        })
        .catch((error) => {
          console.log('Error:', error);
          this.$toast.error('Erro ao buscar os dados do servidor.', '', {
            position: 'topRight'
          });
        });
    },
    companySelected(company) {
      this.companyId = company.id;
    },
    save() {
      this.loading = true;
      ServidoresBancosDadosGeoPerdasService.save({
        ...this.servidor,
        companyId: this.companyId,
        criarDatabases: this.criarDatabases,
        sufixoCustomizado: this.databaseSufixoCustomizado
      })
        .then(() => {
          this.$toast.success('Servidor salvo com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes[
              'GEOPERDAS_SERVIDORES_BANCOS_DADOS_LISTAR'
            ]
          });
        })
        .catch((error) => {
          console.log('Error:', error);
          this.$toast.error(
            'Erro ao salvar os dados de conexão com o servidor.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    },
    testarConexao() {
      this.loading = true;
      ServidoresBancosDadosGeoPerdasService.testConnection(this.servidor)
        .then(() => {
          this.$toast.success(
            'Conexão com o servidor realizada com sucesso.',
            '',
            { position: 'topRight' }
          );
          this.resultadoTesteConexaoServidor = true;
          this.mensagemErroTesteConexaoServidor = null;
          this.obtemDatabasesCriadas();
        })
        .catch((error) => {
          console.log('Error:', error);
          this.$toast.error('Erro ao testar a conexão com o servidor.', '', {
            position: 'topRight'
          });
          this.resultadoTesteConexaoServidor = false;
          this.mensagemErroTesteConexaoServidor = error.data.error;
        })
        .finally(() => (this.loading = false));
    },
    obtemDatabasesCriadas() {
      ServidoresBancosDadosGeoPerdasService.showDatabases(this.servidor)
        .then((response) => (this.databases = response.data))
        .catch((error) => {
          console.log('Error:', error);
          this.mensagemErroTesteConexaoServidor = error.data.error;
          this.$toast.error(
            'Erro ao testar buscar as databases disponíveis no servidor.',
            '',
            { position: 'topRight' }
          );
          this.databases = [];
        });
    }
  },
  watch: {
    servidor: {
      handler() {
        this.resultadoTesteConexaoServidor = false;
        this.mensagemErroTesteConexaoServidor = null;
      },
      deep: true
    },
    resultadoTesteConexaoServidor() {
      if (this.resultadoTesteConexaoServidor) {
        this.obtemDatabasesCriadas();
      }
    }
  }
};
</script>
